const
    testOptions  = {
         PAGE_ROWS:      100
        ,FILTERS_LIMIT:  10
    }
    ,prodOptions = {
         PAGE_ROWS:      400
        ,FILTERS_LIMIT:  100
    }
    ,devParams   = {           ...prodOptions, API_URL: 'https://backend.systrategy.it/roobeek-dev'            }

    ,environment = {

         local:              { ...testOptions, API_URL: 'http://localhost:65001'                               }
        ,stage:              { ...prodOptions, API_URL: 'https://backend.systrategy.it/roobeek-stage'          }
        ,dev:                devParams

        ,testdisco:          devParams
        ,testclear:          devParams
        ,testcairo:          devParams

        ,proddiscovery:      { ...prodOptions, API_URL: 'https://backend.roobeek.wbdiscovery.systrategy.com/roobeek'   }
        ,prodigp:            { ...prodOptions, API_URL: 'https://backend.roobeek.igp.systrategy.com/roobeek'           }
        ,prodcairo:          { ...prodOptions, API_URL: 'https://backend.roobeek.cairorcsmedia.systrategy.com/roobeek' }
        ,prodsole:           { ...prodOptions, API_URL: 'https://backend.roobeek.ilsole24ore.systrategy.com/roobeek'   }

    }
    ,config      = () => ({
        ...( environment[ process.env.REACT_APP_ENV ] || environment[ localStorage.getItem('appid') ] || {} )
        ,ENVIRONMENTS: environment
    })
;

export default config();
